import styled from 'styled-components'

export default styled.div`
  width: 248px;
  height: 35px;
  .date-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    color: #f2f2f2;
    border-bottom: solid 1px #6a6a6a;
    .input-wrapper {
      color: #fff;
      &::before,
      ::after {
        border: none;
      }
      input {
        font-size: 18px;
      }
    }
    .icon-wrapper {
      display: flex;
      width: 22px;
      height: 22px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`
