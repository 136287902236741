/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { createMuiTheme, withStyles, IconButton } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import moment from 'moment'

import DatePickerStyled from './styled'
import CalendarIcon from '../../assets/images/datepicker/calendar-icon.svg'

const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'
const YEAR = 'year'

const materialTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#660c0d',
        fontSize: '12px',
        height: 90,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: 12,
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: '#1f2d39',
        color: '#fff',
      },
      current: {
        color: '#660c0d',
      },
    },
  },
})

const datePickerStyled = () => ({
  dayContainer: {
    fontSize: 16,
    padding: 8,
    margin: '0px 4px',
    color: '#1f2d39',
  },
  hide: {
    opacity: 0.3,
  },
  selectedDay: {
    color: 'white',
    background: '#981214',
    '&:hover': {
      background: 'rgba(0,0,0,1)',
    },
  },
  selectedWeek: {
    background: 'rgba(102,12,13, 0.5)',
  },
  firstDay: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  lastDay: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
})

export class DatePickerComponent extends React.PureComponent {
  state = {
    isDatePickerOpen: false,
  }

  openDatePicker = () => {
    this.setState({
      isDatePickerOpen: true,
    })
  }

  closeDatePicker = () => {
    this.setState({
      isDatePickerOpen: false,
    })
  }

  renderWeekSelected(date, selectedDate, dayInCurrentMonth) {
    let className = this.props.classes.dayContainer
    const isFutureDate = date.isAfter(moment())
    if (!dayInCurrentMonth || isFutureDate) {
      className = `${className} ${this.props.classes.hide}`
    }
    const isSelectedDay = date.isSame(selectedDate)
    if (isSelectedDay) {
      className = `${className} ${this.props.classes.selectedDay}`
    }
    const startDay = moment(selectedDate).startOf('week')
    const endDay = moment(selectedDate).endOf('week').startOf('day')
    const isDayInSelectedWeek = moment(date).isBetween(startDay, endDay)
    const isSameStartDay = date.isSame(startDay)
    const isSameEndDay = date.isSame(endDay)
    let weekClassName = ''
    if (this.props.type === 'week') {
      if (isDayInSelectedWeek || isSameStartDay || isSameEndDay) {
        weekClassName += ` ${this.props.classes.selectedWeek}`
      }
      if (isSameStartDay) {
        weekClassName += ` ${this.props.classes.firstDay}`
      }
      if (isSameEndDay) {
        weekClassName += ` ${this.props.classes.lastDay}`
      }
    }
    return (
      <div className={weekClassName}>
        <IconButton className={className}>
          <span>{date.format('DD')}</span>
        </IconButton>
      </div>
    )
  }

  renderLabelDate = date => {
    let label
    switch (this.props.type) {
      case DAY: {
        label = date.format('D MMM YYYY')
        break
      }
      case WEEK: {
        const startDay = moment(date).startOf('week')
        const endDay = moment(date).endOf('week').startOf('day')
        label = `${startDay.format('D MMM YYYY')} - ${endDay.format('D MMM YYYY')}`
        break
      }
      case MONTH: {
        label = date.format('MMM YYYY')
        break
      }
      case YEAR: {
        label = date.format('YYYY')
        break
      }
      default: {
        label = date.format('D MMM YYYY')
        break
      }
    }
    return label
  }

  onChangeDate = date => {
    const isToday = moment().isSame(moment(date), 'day')
    const isSameDay = moment(this.props.date).isSame(moment(date), 'day')
    const preparedDate = isToday ? moment() : date
    if (!isSameDay) {
      this.props.changeDate(preparedDate)
    }
  }

  getFormatSetting() {
    let setting
    switch (this.props.type) {
      case DAY: {
        setting = {
          views: ['date'],
          openTo: 'date',
        }
        break
      }
      case WEEK: {
        setting = {
          views: ['date'],
          openTo: 'date',
        }
        break
      }
      case MONTH: {
        setting = {
          views: ['year', 'month'],
          openTo: 'month',
        }
        break
      }
      case YEAR: {
        setting = {
          views: ['year'],
          openTo: 'year',
        }
        break
      }
      default: {
        setting = {
          views: ['date'],
          openTo: 'date',
        }
        break
      }
    }
    return setting
  }

  renderDatePicker() {
    const setting = this.getFormatSetting()
    const output = (
      <div className="date-picker-component">
        <DatePicker
          animateYearScrolling
          variant="inline"
          disableFuture
          format="DD MMM YYYY"
          value={moment(this.props.date)}
          open={this.state.isDatePickerOpen}
          renderDay={(date, selectedDate, dayInCurrentMonth) => this.renderWeekSelected(date, selectedDate, dayInCurrentMonth)}
          labelFunc={this.renderLabelDate}
          minDate={this.props.minDate || moment().subtract(2, 'year')}
          maxDate={this.props.maxDate || moment()}
          maxDateMessage={''}
          minDateMessage={''}
          openTo={setting.openTo}
          views={setting.views}
          invalidLabel=""
          invalidDateMessage=""
          onOpen={this.openDatePicker}
          onClose={this.closeDatePicker}
          autoOk
          InputProps={{ className: 'input-wrapper' }}
          onChange={this.onChangeDate}
        />
      </div>
    )
    return output
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePickerStyled className={`${this.props.className} flex flex-column`}>
            <div className="date-wrapper">
              {this.renderDatePicker()}
              <div className="icon-wrapper">
                <img src={CalendarIcon} onClick={this.openDatePicker} />
              </div>
            </div>
          </DatePickerStyled>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    )
  }
}

DatePickerComponent.defaultProps = {
  label: '',
  disabled: false,
}

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  changeDate: PropTypes.func,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default withStyles(datePickerStyled)(DatePickerComponent)
